export default {
    home: {
        home1: '何文田太子道西296號',
        home2: '物業亮點',
        home3: '45戶全新豪華大宅的建築，配備高樓底和全落地玻璃，並提供幾乎1：1的車位比例(43個住戶車位及2個訪客車位)。住宅享有開闊的景觀，前臨翠綠美景，遠眺獅子山。此外，罕見的豪華會所設有21米長的園林泳池，健身房，桑拿浴空和休息區域。這楝大樓位於九龍區傳統豪宅區，周邊名校林立。臨近九龍城和旺角東站，日常生活所需一應俱全。',
        home4: '多個傳統王牌名校',
        home5: '國際中小學的重鎮區域',
        home6: 'Terra位於香港著名的校網，位處九龍區名校網34校網，區內傳統名校為數眾多，屬非常受家長青睞的名校區。',
        home7: '至於中學校網屬九龍城區，知名學府包括拔萃男書院、喇沙書院、瑪利諾修院學校及英皇佐治五世學校等「爭崩頭」名校，校網優勢無可比擬。',
        home8: '俯瞰山脈美景',
        home9: '視野盡收眼底',
        home10: '山景一覽無遺 美不勝收',
        home11: '知名建築師',
        home12: '及設計師團隊精心打造',
        home13: '區內具地標性物業',
        home14: '房屋平面圖',
        home15: '住宅',
        home16: '網內受歡迎的學校包括聖羅撒學校及協恩中學附屬小學等；',
        home17: '供買方參考資料及免責條款',
        home18: '本網站www.terraluxuryhk.com 如被視為廣告則本告示適用。',
        home19: '發展項目名稱：太子道西296號發展項目（「發展項目」）的「隆敍」',
        home20: '備註：太子道西296號發展項目，建於九龍內地段2097號餘段（「該地段」,中文名稱為「隆敍」，英文名稱為 “TERRA”。',
        home21: '區域：何文田',
        home22: '隆敍所位於的街道名稱及門牌號數：太子道西296號',
        home23: '賣方為施行《一手住宅物業銷售條例》的互聯網網站的網址：www.terraluxuryhk.com',
        home24: '本廣告／宣傳資料內載列的相片、圖像、繪圖或素描顯示純屬畫家對有關發展項目之想像。有關相片、圖像、繪圖或素描並非按照比例繪畫及／或可能經過電腦修飾處理。準買家如欲了解發展項目的詳情，請參閱售樓說明書。賣方亦建議準買家到有關發展地盤作實地考察，以對該發展地盤、其周邊地區環境及附近的公共設施有較佳了解。',
        home25: '賣方︰志德投資有限公司',
        home26: '賣方（擁有人）的控權公司︰匯謙有限公司',
        home27: '發展項目的認可人士以其專業身分擔任經營人、董事或僱員的商號或法團: 周余石（香港）有限公司',
        home28: '發展項目的認可人士 : 甘慶邦先生',
        home29: '發展項目的承建商 : 顯利工程有限公司',
        home30: '就發展項目中的住宅物業的出售而代表擁有人行事的律師事務所 : 盧王徐律師事務所',
        home31: '已為發展項目的建造提供貸款或已承諾為該項建造提供融資的認可機構 : 中國銀行（香港）有限公司（已於2023 年12 月5 日解除）',
        home32: '賣方保留權利不時改動建築圖則及其他圖則，隆敍之設計以政府相關部門最後批准之圖則為準。',
        home33: '本廣告在擁有人的同意下由如此聘用的人發布。',
        home34: '賣方建議準買家參閱有關售樓說明書，以了解隆敍的資料。詳情請參閱售樓說明書。',
        home35: '本廣告／宣傳資料僅供參考，並不構成亦不得被詮釋成賣方就隆敍或其任何部份作出任何不論明示或隱含之合約條款、要約、陳述、承諾或保證。',
        home36: '最後更新日期︰2024年7月24日',
    },
    navigator: {
        navigator1: '售樓說明書',
        navigator2: '價單',
        navigator3: '成交紀錄冊',
        navigator4: '銷售安排資料',
        navigator5: '公契',
        navigator6: '鳥瞰照片',
        navigator7: '招標文件',
    },
    footer: {
        footer1: '地址:何文田太子道西296號',
        footer2: '地段編號:九龍内地段第2097',
        footer3: '除特别註明外，本网页内之相片及图像并非于或自发展项目期数或其任何部分拍摄或以发展项目期数或其任何部分作依据製作，并非反映发展项目期数或其任何部分外观、景观、设计、装置、装修物料、设备、设施、服务和使用，亦可能与发展项目期数或其任何部分无关。相关相片及图像并不构成亦不得诠释成作出任何不论明示或隐含之要约、陈述、承诺或保证。',
    },
    language: {
        language: '繁體中文',
        zh: 'zh-CN',
        en: 'en',
        tw: 'zh-TW',
        zhName: '简体中文',
        zhTwName: '繁體中文',
        enName: 'English',
    },
}