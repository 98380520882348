<template>
  <div class="footer">
    <div class="left">
      <img src="@/assets/img/title@2x.png" alt="" />
      <div>{{ $t('footer.footer1') }}<br />{{ $t('footer.footer2') }}</div>
    </div>
    <div class="right">
      {{ $t('footer.footer3') }}
    </div>
  </div>
</template>

<script>

export default {}
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  height: 1.53rem;
  box-sizing: border-box;
  background: #7c6b56;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  font-family: Roboto;
  font-size: 0.16rem;
  font-weight: 400;
  color: #fff;
  padding-left: 2.72rem;
  padding-right: 2.59rem;
  .left {
    padding-top: 0.26rem;
    img {
      width: 2.22rem;
      height: 0.53rem;
    }
    div {
      width: 3rem;
      padding-left: 0.11rem;
      font-family: PingFang TC, PingFang TC;
      font-weight: 300;
      font-size: 0.12rem;
      color: #ffffff;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
  }
  .right {
    width: 5.51rem;
    // height: 0.84rem;
    padding-top: 0.36rem;
    font-family: PingFang TC, PingFang TC;
    font-weight: 400;
    font-size: 0.1rem;
    color: #ffffff;
    line-height: 0.14rem;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}
</style>
